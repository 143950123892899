body {
  color: white;
  background-color: #1e1e1e;
  margin: 0;
  font-family: 'Courier';
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (min-width: 600px) {
  body {
    font-weight: bold;
  }
}
